const API_ENDPOINT = 'https://api-psp.eshmoun.com.tn/v1'; // PROD
// const API_ENDPOINT = 'http://localhost:8080/v1'; // LOCALE
// const API_ENDPOINT = 'https://api-psp.katomi.co/v1'; // STAGING

const API_AUTH = `${API_ENDPOINT}/auth`;
const API_PATIENTS = `${API_ENDPOINT}/patients`;
const API_CALLS = `${API_ENDPOINT}/calls`;
const API_ADVERSE = `${API_ENDPOINT}/adverse`;
const API_QUERY = `${API_ENDPOINT}/queries`;

const API_REQ_HEADER = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};

export {
    API_AUTH,
    API_PATIENTS,
    API_CALLS,
    API_ADVERSE,
    API_REQ_HEADER,
    API_QUERY,
};
